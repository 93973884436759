import axios, { AxiosRequestConfig } from "axios";
import { API_BASE_URL } from "../config/envConstants";
const API_URL_SUFFIX = "/api/";

export class CloudApiClient {
  static httpGet = async (url: string): Promise<any> => {
    try {
      const method = "GET";
      const options: AxiosRequestConfig = {
        method,
        url: url,
        withCredentials: true,
      };
      const res = await axios(options);
      return res.data;
    } catch (err) {
      throw err?.response?.data || {};
    }
  };

  static httpPost = async <T>(
    url: string,
    data: T extends unknown ? unknown : Record<string, unknown>,
  ): Promise<any> => {
    try {
      const method = "POST";
      const headers = {
        "Content-Type": "application/json",
      };
      const options: AxiosRequestConfig = {
        headers,
        method,
        url: url,
        onUploadProgress: p => {
          console.log(p);
        },
        data,
        withCredentials: true,
      };
      const res = await axios(options);
      return res.data;
    } catch (err: any) {
      throw err?.response?.data || {};
    }
  };

  static httpPostFile = async <T>(
    url: string,
    data: T extends unknown ? unknown : Record<string, unknown>,
  ): Promise<any> => {
    try {
      const method = "POST";
      const headers = {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      };
      const options: AxiosRequestConfig = {
        headers,
        method,
        url: url,
        data,
        withCredentials: true,
      };
      // console.log("lii",process.env)
      const res = await axios(options);
      return res.data;
    } catch (err) {
      throw err?.response?.data || {};
    }
  };

  static httpPut = async (url: string, payload: unknown): Promise<any> => {
    try {
      const method = "PUT";
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      };
      const data = JSON.stringify(payload);
      const options: AxiosRequestConfig = {
        headers,
        method,
        url: url,
        data,
        withCredentials: true,
      };
      const res = await axios(options);
      return res.data;
    } catch (err) {
      throw err?.response?.data || {};
    }
  };

  static httpPatch = async (
    url: string,
    payload: Record<string, unknown>,
  ): Promise<any> => {
    try {
      const method = "PATCH";
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      };
      const data = JSON.stringify(payload);
      const options: AxiosRequestConfig = {
        headers,
        method,
        url: url,
        data,
        withCredentials: true,
      };
      const res = await axios(options);
      return res.data;
    } catch (err) {
      throw err?.response?.data || {};
    }
  };

  static httpDelete = async (url: string): Promise<any> => {
    try {
      const headers = {};
      const method = "DELETE";
      const options: AxiosRequestConfig = {
        headers,
        method,
        url: url,
        withCredentials: true,
      };
      const res = await axios(options);
      return res.data;
    } catch (err) {
      throw err?.response?.data || {};
    }
  };

  static async uploadImage(formData: unknown): Promise<any> {
    try {
      const URL = `${API_BASE_URL + API_URL_SUFFIX}Fileupload`;
      const res = await axios.post(URL, formData, {
        // receive two parameter endpoint url ,form data
      });
      return res.data;
    } catch (err) {
      throw err?.response?.data || {};
    }
  }
}
