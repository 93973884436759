import { action, makeObservable, observable } from "mobx";
import { getLoggedInUserApi } from "../../api/authApis";
import {
  IGetCurrentUserRes,
  ILoggedInUser,
} from "../../interfaces/authInterfaces";
import { ErrorReporter } from "../../libs/ErrorReporter";
// import { RealTimeEvents } from "../../libs/RealTimeEvents";

type IAuthStoreFields = {
  isLoading: boolean;
  isUserPresent: boolean;
  loggedInUser: Partial<ILoggedInUser>;
};

const initialValues: IAuthStoreFields = {
  isLoading: true,
  isUserPresent: false,
  loggedInUser: {},
};

class AuthStore {
  isLoading: boolean;
  isUserPresent: boolean;
  loggedInUser: Partial<ILoggedInUser>;

  constructor() {
    this.reset();
    // makeAutoObservable(this, {}, { autoBind: true });
    makeObservable(this, {
      isLoading: observable,
      setLoading: action,
      isUserPresent: observable,
      setLoggedInUser: action,
      getUserFromServer: action,
      loggedInUser: observable,
    });
  }

  reset = () => {
    Object.keys(initialValues).forEach(key => {
      this[key] = initialValues[key];
    });
  };

  setLoading(value: boolean) {
    this.isLoading = value;
  }

  setLoggedInUser = (values: ILoggedInUser) => {
    this.loggedInUser = values;
    this.isUserPresent = true;
    this.isLoading = false;
  };

  getUserFromServer = async (): Promise<ILoggedInUser> => {
    const response: IGetCurrentUserRes = await getLoggedInUserApi().catch(
      error => {
        this.setLoading(false);
        // RealTimeEvents.disconnect();
        throw error;
      },
    );
    if (!response?.payload?.id) throw new Error("user id not found");

    const user = response.payload;

    const formattedUser: ILoggedInUser = {
      //Id of user
      id: user.id.toString(),
      email: user.emailAddress,
      username: user.accounts?.displayName || "",
      is2faEnabled: user.authIs2faEnabled,
      //Id of organization
      userAccountId: user.accounts.id.toString(),
      displayPicture: user.accounts.displayPictureMediaPath,
      name: user.accounts.name,
      sessionPrivilege: user.sessionPrivilege,
      authenticationMethod: user?.authenticationMethod,
    };

    this.setLoggedInUser(formattedUser);
    return formattedUser;
  };

  initialize = async () => {
    try {
      await this.getUserFromServer();
    } catch (error) {
      const ory_session_token = null;
      const mecha_auth_token = null;
      if (ory_session_token || mecha_auth_token) {
        ErrorReporter.collect(
          error,
          "Error while fetching loggedIn user from server",
        );
      }
      throw error;
    }
  };
}

export default new AuthStore();
