import { useEffect } from "react";
import { useStore } from "../../storeManager/stores";
import { ErrorReporter } from "../../libs/ErrorReporter";

const resetPasswordLink = "/auth/set-password";

const WithAuth = (ComposedComponent: any): any => {
  const WithAuthentication = (props: any) => {
    const authStore = useStore().authStore;

    const getSession = async () => {
      //Initilize user in authStore
      try {
        // not calling whoami if it is a reset password page
        if (props?.router?.state?.pathname !== resetPasswordLink) {
          await authStore.initialize();
        }
      } catch (error) {
        ErrorReporter.collect(error, "Error while initializing auth store");
        console.log("error while initializing authStore", error);
      }
    };

    useEffect(() => {
      getSession();
    });

    return <ComposedComponent {...props} />;
  };

  return WithAuthentication;
};

export default WithAuth;
