import type { AppProps } from "next/app";
import { Toaster } from "react-hot-toast";
import "tailwind-config/dist/index.css";
import AllProviders from "../src/storeManager/provider";
import {
  themes as appTheme,
  applyTheme,
} from "@mecha/design-components/dist/src/styles/theme";
import WithAuth from "../src/components/Hoc/withAuth";
import { useState, useEffect } from "react";
import { ThemeProvider } from "next-themes";
import { EventTrackerInitialiser } from "../sentry.client.config";
import Logger from "../src/libs/Logger";
import { isWindowUndefined } from "../src/utils/isWindowUndefined";

//initializing logger class
Logger.createLogs();
const log = new Logger({ module: "Authentication" });
log.info({}, "Logging initialized");

// apply theme(appTheme.default);
function MyApp({ Component, pageProps }: AppProps) {
  const [isTheme, setTheme] = useState<any>();
  new EventTrackerInitialiser().initialise();
  applyTheme(isTheme);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (
      !isWindowUndefined(window) &&
      (localStorage.theme === "dark" ||
        (!("theme" in localStorage) &&
          window.matchMedia("(prefers-color-scheme: dark)").matches))
    ) {
      //check if there is any key for theme in local storage and if the system color theme is dark
      document.documentElement.classList.remove("light"); //OPTIONAL - remove light from the html document if any
      document.documentElement.classList.add("dark"); // add dark to the <html></html> itself as <html class='dark'></html>
      setTheme(appTheme.dark);
    } else {
      document.documentElement.classList.remove("dark"); // remove dark from the html document if any
      document.documentElement.classList.add("light"); //OPTIONAL - add light to the <html></html> itself as <html class='light'></html>
      setTheme(appTheme.default);
    }
  }, []);

  const AnyComponent = Component as any;

  return (
    <AllProviders>
      <ThemeProvider>
        <Toaster containerStyle={{ zIndex: "99999" }} />
        <AnyComponent {...pageProps} />
      </ThemeProvider>
    </AllProviders>
  );
}

export default WithAuth(MyApp);
