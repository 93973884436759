export interface IAddDevicePayload {
  displayPictureMediaPath: string;
}

export enum DeviceModalSteps {
  STEP1 = 1,
  STEP2 = 2,
  STEP3 = 3,
  STEP4 = 4,
  STEP5 = 5,
}

export enum DeviceProvisionedStatus {
  SUCCESS = 1,
  FAILED = 0,
  ERROR = -1,
}
export interface IEventsData {
  name: string;
  applicationId: string;
  payload: any;
}

export interface ILogsData {
  name: string;
  applicationId: string;
  payload: any;
}
export interface IDeviceSearch {
  tags: { keyName: string; keyValue: string }[];
  searchText: string;
}

export interface IAddDeviceReq {
  name: string;
  tags: string;
  deviceTemplateId: number;
  provisioningCode: string;
}

export interface IUpdateDeviceReqTag {
  keyName: string;
  keyValue: string;
}

export interface IUpdateDeviceReq {
  name?: string;
  tags?: IUpdateDeviceReqTag[];
  deviceTemplateId?: number;
  provisioningCode?: string;
}

export interface IAddDeviceRes {
  displayPictureMediaPath: string;
}

export interface IPinUnpinDeviceRes {
  success: boolean;
  message: string;
  status: string;
  statusCode: number;
  errorCode: null;
  subErrors: null;
  payload: boolean;
}

// Device Events Interface
export interface IDeviceEventsRes {
  success: boolean;
  message: string;
  status: string;
  statusCode: number;
  errorCode: null;
  subErrors: null;
  payload: boolean;
}

export interface IWebSocketPing {
  eventCode: string;
  data: any;
  deviceId: string;
}

export type IDeviceStatus = IDeviceStatusObj[];

export interface IDeviceStatusObj {
  id: number;
  tenantCode: string;
  deviceId: string;
  statusKey: string;
  statusValue: string;
  createdAt: string;
  updatedAt: string;
}

export interface IDeviceContent {
  id: number;
  alias: string;
  tenantCode: string;
  deviceId: string;
  accountId: number;
  userAccountId: number;
  name: string;
  arch: any;
  os: any;
  make: string;
  model: string;
  serialNumber: string;
  deviceTemplateId: number;
  userConfigProfileId: number;
  systemConfigProfileId: number;
  userAppsProfileId: number;
  systemAppsProfileId: number;
  certId: string;
  certFingerprint: string;
  displayIconMediaPath: string;
  createdAt: string;
  updatedAt: string;
  deviceTags: any[];
  deviceIpConfigs: any[];
  deviceTunnelConfig: ITunnelConfigs;
  pinnedDevice: any;
  deviceTelemetryConfig: any;
  deviceTemplate: IDeviceTemplates;
  deviceStatuses: IDeviceStatus;
}

export interface ITunnelConfigs {
  id: number;
  tenantCode: string;
  deviceId: string;
  tunnelUrl: string;
  status: number;
  createdAt: string;
  updatedAt: string;
  enabled: boolean;
}

export interface IDeviceLogsContent {
  deviceId: string;
  deviceModel: string;
  deviceTags: string;
  deviceMake: string;
  deviceEnv: number;
  deviceArch: string;
  deviceOs: string;
  deviceOsVersion: string;
  deviceOsIsSigned: boolean;
  deviceTz: string;
  agentAppId: string;
  agentAppVersion: string;
  agentVersion: string;
  applicationId: number;
  accountId: number;
  tenantCode: string;
  checksumSha1: string;
  timestamp: string;
  name: string;
  group: string;
  path: string;
  payload: string;
  level: string;
  system: boolean;
  size: number;
  tags: string;
  url: string;
}

export interface IDeviceEventsPayload {
  content: IDeviceContent[];
  pageSize: number;
  totalPages: number;
  totalRecordCount: number;
}

export interface IDeviceTemplates {
  id: number;
  tenantCode: string;
  name: string;
  description: string;
  setupInstructions: string | Record<number, string>[];
  iconUrl: string;
  createdAt: string;
  generation: number;
}

export interface IVisualizationFieldsObject {
  type: string;
  timeInterval: string;
  aggregateFunction: string;
  allowedIntervals: string[];
}

export interface IVisualizationObject {
  network: Partial<IVisualizationFieldsObject>;
  cpu: Partial<IVisualizationFieldsObject>;
  load: Partial<IVisualizationFieldsObject>;
  disk: Partial<IVisualizationFieldsObject>;
  file: Partial<IVisualizationFieldsObject>;
  memory: Partial<IVisualizationFieldsObject>;
}
export interface IDeviceMetrics {
  fromDate: string;
  toDate: string;
  visualization: Partial<IVisualizationObject>;
  aggregateFunc: string;
}

export interface IMetricsRequest {
  metrics: string[];
  deviceIds: string[];
  timeInterval: string;
  fromDate: string;
  toDate: string;
  aggregateFunc?: string;
  timezone?: string;
}
export interface IMetricsResponse {
  errorCode: any;
  message: string;
  payload?: Payload;
  status: string;
  statusCode: number;
  subErrors: any;
  success: boolean;
}

export interface Payload {
  status: string;
  data: Data;
}

export interface Data {
  resultType: string;
  result: Result[];
}

export interface Result {
  metric: Metric;
  values: [number, string][];
}

export interface Metric {
  __name__: string;
  instance: string;
  job: string;
}

export interface IMetricsIntervalDurations {
  label: string;
  value: number;
}

export interface ISnapshotRequest {
  name: string;
  description: string;
  status: string;
  includes: string;
  deviceId: string;
}

export interface IKeyValue {
  value: string;
  key: string;
}
export interface IDuration {
  label: string;
  value: string;
  interval: string[];
  from: string;
  to: string;
}

export interface IDecodedQuery {
  duration: IDuration;
  level: IKeyValue;
  group: IKeyValue;
  searchText: string;
  limit: number;
  page?: string;
}

export interface IDeviceProvisionReq {
  provisionCode: string;
  deviceTemplateId: number;
}
export interface IDeviceProvisionRes {
  requestId: number;
  deviceId: string;
}
export interface IDeviceProvisionStatusRes {
  statusCode: IDeviceProvisionStatus;
  status: number;
}

export enum IDeviceProvisionStatus {
  PENDING = 0,
  FAILED = 10,
  COMPLETED = 20,
}

export interface IDeviceStatusRes {
  success: boolean;
  message: string;
  status: string;
  statusCode: number;
  errorCode: any;
  subErrors: any;
  payload: IDeviceStatusPayload;
}

export interface IDeviceStatusPayload {
  content: IDeviceStatusContent[] | [];
  totalPages: number;
  totalRecordCount: number;
  pageSize: number;
}

export interface IDeviceStatusContent {
  id: number;
  tenantCode: string;
  deviceId: string;
  statusKey: string;
  statusValue: string;
  createdAt: string;
  updatedAt: string;
}
export interface IDeviceMonitoring {
  title: string;
  icon: React.ReactNode;
}

export interface IFirewallRule {
  id: number;
  tenantCode: string;
  deviceId: string;
  name: string;
  direction: string;
  roles: string;
  protocol: string;
  allowPorts: string;
  status: number;
  createdAt: string;
  updatedAt: string;
}
