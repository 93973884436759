import dayjs from "dayjs";
import { getCurrentTheme } from "../utils/getCurrentTheme";

export const LOGIN_URL = "/auth/signin";
export const UNAUTHORIZED_STATUS = 401;
export const FORBIDDEN_STATUS = 403;
export const MAX_PINNED_DEVICE = 6;
export const FETCH_PINNED_DEVICES = "pinned";
export const FETCH_UNPINNED_DEVICES = "unPinned";

export const DETAILED_DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

export const DEVICE_TABS = {
  APPS: {
    value: "APPS",
    text: "Apps",
  },
  ACTIVITY: {
    value: "ACTIVITY",
    text: "Activity",
  },
  SETTINGS: {
    value: "SETTINGS",
    text: "Settings",
  },
};

export const defaultDeviceImages = [
  // device-image1.png
  {
    id: 0,
    src: "/assets/img/devices/device-image1.png",
    name: "device-image1.png",
  },
  {
    id: 1,
    src: "/assets/img/devices/device-image2.png",
    name: "device-image2.png",
  },
  {
    id: 2,
    src: "/assets/img/devices/device-image3.png",
    name: "device-image3.png",
  },
  {
    id: 3,
    src: "/assets/img/devices/device-image4.png",
    name: "device-image4.png",
  },
  {
    id: 4,
    src: "/assets/img/devices/device-image5.png",
    name: "device-image5.png",
  },
  {
    id: 5,
    src: "/assets/img/devices/mecha-compute.png",
    name: "mecha-compute.png",
  },
  {
    id: 6,
    src: "/assets/img/devices/raspberry-pi.png",
    name: "raspberry-pi.png",
  },
  {
    id: 7,
    src: "/assets/img/devices/generic-device.png",
    name: "generic-device.png",
  },
];

export const defaultAvatars = [
  {
    id: 0,
    src: "/assets/avatars/Avatar-1.png",
    name: "Avatar-1.png",
  },
  {
    id: 1,
    src: "/assets/avatars/Avatar-2.png",
    name: "Avatar-2.png",
  },
  {
    id: 2,
    src: "/assets/avatars/Avatar-3.png",
    name: "Avatar-3.png",
  },
  {
    id: 3,
    src: "/assets/avatars/Avatar-4.png",
    name: "Avatar-4.png",
  },
  {
    id: 4,
    src: "/assets/avatars/Avatar-5.png",
    name: "Avatar-5.png",
  },
  {
    id: 5,
    src: "/assets/avatars/Avatar-6.png",
    name: "Avatar-6.png",
  },
  {
    id: 6,
    src: "/assets/avatars/Avatar-7.png",
    name: "Avatar-7.png",
  },
  {
    id: 7,
    src: "/assets/avatars/Avatar-8.png",
    name: "Avatar-8.png",
  },
];

export const trophyData = [
  {
    id: 0,
    src: "/assets/trophys/trophy-1.png",
  },
  {
    id: 1,
    src: "/assets/trophys/trophy-2.png",
  },
  {
    id: 2,
    src: "/assets/trophys/trophy-3.png",
  },
  {
    id: 3,
    src: "/assets/trophys/trophy-4.png",
  },
  {
    id: 4,
    src: "/assets/trophys/trophy-5.png",
  },
  {
    id: 5,
    src: "/assets/trophys/trophy-6.png",
  },
];

export const metricsIntervalDurations = [
  { label: "60s", value: 60 * 1000 },
  { label: "1h", value: 60 * 60 * 1000 },
  { label: "1d", value: 60 * 60 * 24 * 1000 },
];

export const durations = [
  {
    label: "Last 5 minutes",
    value: "5m",
    interval: ["minute"],
    from: dayjs().subtract(5, "minute").format(DETAILED_DATE_FORMAT),
    to: dayjs().format(DETAILED_DATE_FORMAT),
    intervalValue: 60 * 5,
  },
  {
    label: "Last 15 minutes",
    value: "15m",
    interval: ["minute"],
    from: dayjs().subtract(15, "minute").format(DETAILED_DATE_FORMAT),
    to: dayjs().format(DETAILED_DATE_FORMAT),
    intervalValue: 60 * 15,
  },
  {
    label: "Last 30 minutes",
    value: "30m",
    interval: ["minute"],
    from: dayjs().subtract(30, "minute").format(DETAILED_DATE_FORMAT),
    to: dayjs().format(DETAILED_DATE_FORMAT),
    intervalValue: 60 * 30,
  },
  {
    label: "Last 1 hour",
    value: "1h",
    interval: ["minute"],
    from: dayjs().subtract(1, "hour").format(DETAILED_DATE_FORMAT),
    to: dayjs().format(DETAILED_DATE_FORMAT),
    intervalValue: 60 * 60,
  },
  {
    label: "Last 3 hours",
    value: "3h",
    interval: ["minute", "hour"],
    from: dayjs().subtract(3, "hour").format(DETAILED_DATE_FORMAT),
    to: dayjs().format(DETAILED_DATE_FORMAT),
    intervalValue: 60 * 60 * 3,
  },
  {
    label: "Last 6 hours",
    value: "6h",
    interval: ["minute", "hour"],
    from: dayjs().subtract(6, "hour").format(DETAILED_DATE_FORMAT),
    to: dayjs().format(DETAILED_DATE_FORMAT),
    intervalValue: 60 * 60 * 6,
  },
  {
    label: "Last 12 hours",
    value: "12h",
    interval: ["minute", "hour"],
    from: dayjs().subtract(12, "hour").format(DETAILED_DATE_FORMAT),
    to: dayjs().format(DETAILED_DATE_FORMAT),
    intervalValue: 60 * 60 * 12,
  },
  {
    label: "Last 24 hours",
    value: "24h",
    interval: ["hour"],
    from: dayjs().subtract(24, "hour").format(DETAILED_DATE_FORMAT),
    to: dayjs().format(DETAILED_DATE_FORMAT),
    intervalValue: 60 * 60 * 24,
  },
  {
    label: "Last 7 days",
    value: "7d",
    interval: ["hour", "day"],
    from: dayjs().subtract(1, "week").format(DETAILED_DATE_FORMAT),
    to: dayjs().format(DETAILED_DATE_FORMAT),
    intervalValue: 60 * 60 * 24 * 7,
  },
  // {
  //   label: "This week",
  //   value: "week",
  //   interval: ["hour", "day"],
  //   from: dayjs().day(0).format(DETAILED_DATE_FORMAT),
  //   to: dayjs().format(DETAILED_DATE_FORMAT),
  //   intervalValue: 60 * 60 * 24 * dayjs().diff(dayjs().day(0), "day")
  // },
];

export const logLevels = [
  {
    value: "All",
    key: "all",
  },
  {
    value: "Debug",
    key: "debug",
  },
  {
    value: "Error",
    key: "error",
  },
  {
    value: "Info",
    key: "info",
  },
  {
    value: "Warn",
    key: "warn",
  },
];

export const aggregrateArray = ["SUM", "AVG", "COUNT", "MAX"];

export const verticalGridInterval = 60 * 60 * 12 * 1000; //12 Hours Vertical Grid

export const logLimits = [100, 1000];

export const pageSizes = [10, 20, 30, 40, 50, 60, 70, 80, 90];

export enum PageTitles {
  VERIFY_EMAIL = "Verify Email",
  SIGN_IN = "Sign In",
  SIGN_UP = "Sign Up",
  FORGOT_PASSWORD = "Forgot Password",
  TWO_FACTOR_AUTHENTICATION = "2FA",
  RESET_PASSWORD = "Reset Password",
  CONFIRM_ACCESS = "Confirm Access",
}

export enum metricsDataConstants {
  CPU = "CPU",
  DISK = "DISK",
  LOAD = "LOAD",
  FILES = "FILES",
  NETWORK = "NETWORK",
  MEMORY = "MEMORY",
}

export const metricsData = [
  "CPU",
  "DISK",
  "LOAD",
  "FILES",
  "NETWORK",
  "MEMORY",
];

export const MultiSelectColourStyles = () => {
  const currTheme = getCurrentTheme();
  return {
    input: styles => {
      return {
        ...styles,
        color: currTheme === "dark" ? "#FFFFFF" : "#15171D",
        borderRadius: "5px",
      };
    },
    multiValue: styles => {
      return {
        ...styles,
        background: currTheme === "dark" ? "#18212F" : "#EFF4F9",
        borderRadius: "5px",
      };
    },
    multiValueLabel: styles => ({
      ...styles,
      color: currTheme === "dark" ? "#ADB8CD" : "#646D8A",
      background: currTheme === "dark" ? "#18212F" : "#EFF4F9",
    }),
    multiValueRemove: styles => ({
      ...styles,
      color: currTheme === "dark" ? "#ADB8CD" : "#646D8A",
      background: currTheme === "dark" ? "#18212F" : "#EFF4F9",
      cursor: "pointer",
      borderRadius: "0",
      ":hover": {
        background: currTheme === "dark" ? "#18212F" : "#EFF4F9",
        color: currTheme === "dark" ? "#ADB8CD" : "#646D8A",
      },
    }),
    menuList: styles => ({
      ...styles,
      color: currTheme === "dark" ? "#ADB8CD" : "#646D8A",
      background: currTheme === "dark" ? "#18212F" : "#FDFDFD",
      cursor: "pointer",
      borderRadius: "0",
      padding: 0,
      ":hover": {
        background: currTheme === "dark" ? "#18212F" : "#EFF4F9",
        color: currTheme === "dark" ? "#ADB8CD" : "#646D8A",
      },
    }),
    option: styles => ({
      ...styles,
      color: currTheme === "dark" ? "#ADB8CD" : "#646D8A",
      background: currTheme === "dark" ? "#18212F" : "#FDFDFD",
      cursor: "pointer",
      borderRadius: "0",
      ":hover": {
        background: currTheme === "dark" ? "#18212F" : "#EFF4F9",
        color: currTheme === "dark" ? "#FFFFFF" : "#15171D",
      },
    }),
  };
};

export enum cloudStatus {
  ENABLE_REQUESTED = 0,
  ENABLED = 10,
  DISABLE_REQUESTED = 20,
  DISABLED = 30,
}

export const DEVICE_IP_VERSION = 4;

const chartYScale = (yCoordinateText, currTheme) => {
  const title = {
    display: true,
    text: ` Value in ${yCoordinateText || ""}`,
  };
  const grid = {
    display: true,
    color:
      currTheme === "dark" ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.1)",
  };

  if (yCoordinateText) {
    return {
      title,
      grid,
    };
  } else {
    return {
      grid,
    };
  }
};

export const chartLegend = legend => {
  if (legend) {
    return {
      labels: {
        usePointStyle: true,
      },
      position: "top" as const,
      align: "end" as const,
    };
  } else {
    return { display: false };
  }
};

export const chartOptions = (yCoordinateText?: string, legend?: boolean) => {
  const currTheme = getCurrentTheme();
  return {
    responsive: true,
    height: 200,
    maintainAspectRatio: false,
    plugins: {
      legend: chartLegend(legend),
    },
    scales: {
      x: {
        grid: {
          display: true,
          color:
            currTheme === "dark"
              ? "rgba(255, 255, 255, 0.1)"
              : "rgba(0, 0, 0, 0.1)",
        },
      },
      y: chartYScale(yCoordinateText, currTheme),
    },
  };
};

export enum WebSocketEventCode {
  ENABLED_IP = "ENABLED_IP",
  ENABLED_DSN = "ENABLED_DSN",
  DISABLED_IP = "DISABLED_IP",
  DISABLED_DSN = "DISABLED_DSN",
  DEVICE_STATUS_UPDATE = "DEVICE_STATUS_UPDATE",
  DEVICE_PROVISIONING_COMPLETE = "DEVICE_PROVISIONING_COMPLETE",
}

export enum AppModule {
  DEVICE_DETAILS = "DEVICE_DETAILS",
  DEVICE_LISTING = "DEVICE_LISTING",
}

export enum FirewallDirection {
  INBOUND = "INBOUND",
  OUTBOUND = "OUTBOUND",
}

export const PASSWORD_STRENGTH_TYPE = {
  WEAK: "weak",
  MEDIUM: "medium",
  STRONG: "strong",
};

export const getColorBasedOnStrength = passwordStrength => {
  if (passwordStrength === PASSWORD_STRENGTH_TYPE.WEAK) {
    return "#E4002B";
  } else if (passwordStrength === PASSWORD_STRENGTH_TYPE.MEDIUM) {
    return "#FEDD00";
  } else if (passwordStrength === PASSWORD_STRENGTH_TYPE.STRONG) {
    return "#00AA13";
  } else {
    return "#EBECF0";
  }
};

export const getWidthBasedOnStrength = passwordStrength => {
  if (passwordStrength === PASSWORD_STRENGTH_TYPE.WEAK) {
    return "w-1/3";
  } else if (passwordStrength === PASSWORD_STRENGTH_TYPE.MEDIUM) {
    return "w-2/3";
  } else if (passwordStrength === PASSWORD_STRENGTH_TYPE.STRONG) {
    return "w-full";
  } else {
    return "w-0";
  }
};

export const mechaEmailVerificationId = "mecha.email_verification.id";


export const ORYErrors = {
  INVALID_CREDS: {
    code: 4000006,
    message:
      "The provided credentials are invalid, check for spelling mistakes in your password, username or email address",
  },
  UNVERIFIED_EMAIL: {
    code: 4000010,
    message:
      "Account not active yet. Did you forget to verify your email address?",
  },
  TWOFAENABLED: {
    code: 1010004,
    message: "Please complete the second authentication challenge.",
  },
};

export enum MetricsTypes {
  CPU_UTILIZATION = "SYSTEM_CPU_UTILIZATION", 
  DISK_UTILIZATION = "SYSTEM_DISK_IO", 
  FILE_SYSTEM_UTILIZATION = "SYSTEM_FILESYSTEMS_USAGE", 
  SYSTEM_LOAD_UTILIZATION = "SYSTEM_CPU_LOAD_AVERAGE_15M", 
  NETWORK_UTILIZATION = "SYSTEM_NETWORK_IO", 
  MEMORY_UTILIZATION = "SYSTEM_MEMORY_USAGE", 
}

