import { API_BASE_URL } from "../../config/envConstants";
import { IResponse } from "../../interfaces/api";
import {
  IAddDeviceReq,
  IDeviceContent,
  IDeviceEventsPayload,
  IDeviceProvisionReq,
  IDeviceProvisionRes,
  IDeviceProvisionStatusRes,
  IDeviceStatusRes,
  IDeviceTemplates,
  IMetricsRequest,
  IPinUnpinDeviceRes,
  ISnapshotRequest,
  IUpdateDeviceReq,
} from "../../interfaces/devicesInterfaces";
import { removeEmpty } from "../../utils/object";
import { convertToQuery } from "../../utils/queryParams";
import { CloudApiClient } from "../cloudApiClient";

const API_VERSION = "v1";
const DEVICES_API_PREFIX = `${API_VERSION}/devices`;
const API_URL_SUFFIX = "/api/";
const API_URL = API_BASE_URL + API_URL_SUFFIX + DEVICES_API_PREFIX;

export const addDeviceApi = (
  body: IAddDeviceReq,
): Promise<IResponse<IDeviceContent>> => {
  return CloudApiClient.httpPost(`${API_URL}/add`, body);
};

export const UpdateDeviceInfoApi = (
  id: string,
  body: IUpdateDeviceReq,
): Promise<IResponse<boolean>> => {
  return CloudApiClient.httpPut(`${API_URL}/${id}/update`, body);
};

export const getDevicesApi = (
  pageNo: number,
  pageSize: number,
  userId: string,
  searchText: string,
  tags: { keyName: string; keyValue: string }[],
): Promise<IResponse<IDeviceEventsPayload>> => {
  const query = convertToQuery(
    removeEmpty({
      userAccountId: userId,
      searchText,
      pageNo,
      pageSize,
      sortBy: "createdAt",
      sortDirection: "DESC",
    }),
  );
  return CloudApiClient.httpPost(`${API_URL}/get?${query}`, tags);
};

export const getSingleDevice = (
  deviceId: string,
): Promise<IResponse<IDeviceContent>> => {
  return CloudApiClient.httpGet(`${API_URL}/${deviceId}`);
};

export const getPinnedDevicesApi = (
  pageSize: number,
  userId: string,
): Promise<IResponse<IDeviceEventsPayload>> => {
  const query = convertToQuery({
    userAccountId: userId,
    pageSize,
    pinned: true,
    sortBy: "pinnedDevice.createdAt",
    sortDirection: "DESC",
  });
  return CloudApiClient.httpGet(`${API_URL}/get?${query}`);
};

export const pinUnpinDevice = (
  id: string,
  pinStatus: boolean,
): Promise<IResponse<IPinUnpinDeviceRes>> => {
  const query = convertToQuery({
    toPin: pinStatus,
  });
  return CloudApiClient.httpPost(
    `${API_BASE_URL + API_URL_SUFFIX + API_VERSION}/pin-device/${id}?${query}`,
    {},
  );
};

export const getDeviceTemplates = (): Promise<
  IResponse<IDeviceTemplates[]>
> => {
  return CloudApiClient.httpGet(
    `${API_BASE_URL + API_URL_SUFFIX + API_VERSION}/device-templates`,
  );
};

// Device details Apis

export const getDeviceStateApi = (body: any): any => {
  return CloudApiClient.httpPost(`${API_URL}/state`, body);
};

export const getDeviceEventsApi = (body: any): any => {
  return CloudApiClient.httpPost(`${API_URL}/events`, body);
};

export const getDeviceLogsApi = (body: any): any => {
  return CloudApiClient.httpPost(`${API_URL}/logs`, body);
};

export const getDeviceMetricsAggregateApi = (body: any): any => {
  return CloudApiClient.httpPost(`${API_URL}/metrics/aggregate`, body);
};

export const resetOrUnlinkDevice = (deviceId: string, deleteOption: number) => {
  return CloudApiClient.httpDelete(
    `${API_URL}/${deviceId}?deleteOption=${deleteOption}`,
  );
};

export const getDeviceMetrics = (body: IMetricsRequest) => {
  return CloudApiClient.httpPost(`${API_URL}/metrics`, body);
};

export const getSnapshotsApi = () => {
  return CloudApiClient.httpGet(`${API_URL}/snapshots`);
};

export const addSnapShotApi = (body: ISnapshotRequest) => {
  return CloudApiClient.httpPost(`${API_URL}/snapshots`, body);
};

export const addDeviceProvisionApi = (
  body: IDeviceProvisionReq,
): Promise<IResponse<IDeviceProvisionRes>> => {
  return CloudApiClient.httpPost(`${API_URL}/provision-requests/new`, body);
};

export const getDeviceProvisionStatus = (
  id: number,
): Promise<IResponse<IDeviceProvisionStatusRes>> => {
  return CloudApiClient.httpPost(
    `${API_URL}/provision-requests/status?id=${id}`,
    {},
  );
};

export const requestEnableDeviceIp = (device_id_alias: string) => {
  return CloudApiClient.httpPut(`${API_URL}/${device_id_alias}/ip/enable`, {});
};

export const requestDisableDeviceIp = (device_id_alias: string) => {
  return CloudApiClient.httpPut(`${API_URL}/${device_id_alias}/ip/disable`, {});
};

export const requestEnableDeviceDns = (deviceId: number) => {
  return CloudApiClient.httpPost(
    `${API_URL}/${deviceId}/request-enable-dns`,
    {},
  );
};

export const requestDisableDeviceDns = (deviceId: number) => {
  return CloudApiClient.httpPut(
    `${API_URL}/${deviceId}/request-disable-dns`,
    {},
  );
};

export const updateTelemetry = (deviceId: number, body) => {
  return CloudApiClient.httpPost(`${API_URL}/${deviceId}/telemetry`, body);
};

export const getDeviceStatus = (id: string): Promise<IDeviceStatusRes> => {
  return CloudApiClient.httpGet(
    `${
      API_BASE_URL + API_URL_SUFFIX + API_VERSION
    }/device-status?deviceId=${id}`,
  );
};

export const updateDeviceInfo = (deviceId: string, body) => {
  return CloudApiClient.httpPut(`${API_URL}/${deviceId}/update`, body);
};

export const CheckAliasApi = (alias: string): Promise<any> => {
  return CloudApiClient.httpGet(`${API_URL}/check-alias-inuse?alias=${alias}`);
};

export const getFirewallRules = (
  device_id_alias: string,
  direction: string,
) => {
  return CloudApiClient.httpGet(
    `${API_URL}/${device_id_alias}/firewall-rules?direction=${direction}&sortDirection=ASC&all`,
  );
};

export const updateFirewallRule = (
  device_id_alias: string,
  ruleId: string,
  body,
) => {
  return CloudApiClient.httpPut(
    `${API_URL}/${device_id_alias}/firewall-rules/${ruleId}/update`,
    body,
  );
};

export const addFirewallRule = (device_id_alias: string, body) => {
  return CloudApiClient.httpPut(
    `${API_URL}/${device_id_alias}/firewall-rules/add`,
    body,
  );
};

export const deleteFirewallRule = (device_id_alias: string, ruleId: string) => {
  return CloudApiClient.httpDelete(
    `${API_URL}/${device_id_alias}/firewall-rules/${ruleId}/delete`,
  );
};
