import dayjs from "dayjs";
import { action, observable, makeObservable } from "mobx";
import { getDeviceMetrics } from "../../api/devicesApis";
import {
  IDeviceMetrics,
  IVisualizationFieldsObject,
} from "../../interfaces/devicesInterfaces";
import { ErrorReporter } from "../../libs/ErrorReporter";
import { DETAILED_DATE_FORMAT, MetricsTypes } from "../../config/constants";

type IDeviceMetricsFields = {
  isLoading: boolean;
  metrics: Partial<IDeviceMetrics>;
};

const initialValues: IDeviceMetricsFields = {
  isLoading: false,
  metrics: {
    fromDate: dayjs().subtract(5, "minute").format(DETAILED_DATE_FORMAT),
    toDate: dayjs().format(DETAILED_DATE_FORMAT),
    visualization: {
      cpu: {
        type: "cpu",
        timeInterval: "60s",
        aggregateFunction: "",
        allowedIntervals: [],
      },
      network: {
        type: "network",
        timeInterval: "60s",
        aggregateFunction: "",
        allowedIntervals: [],
      },
      disk: {
        type: "disk",
        timeInterval: "60s",
        aggregateFunction: "",
        allowedIntervals: [],
      },
      file: {
        type: "file",
        timeInterval: "60s",
        aggregateFunction: "",
        allowedIntervals: [],
      },
      load: {
        type: "load",
        timeInterval: "60s",
        aggregateFunction: "",
        allowedIntervals: [],
      },
      memory: {
        type: "memory",
        timeInterval: "60s",
        aggregateFunction: "",
        allowedIntervals: [],
      },
    },
    aggregateFunc: "SUM",
  },
};

class MetricesStore {
  isLoading = false;
  metrics: Partial<IDeviceMetrics> = initialValues.metrics;
  interval = "60s";
  deviceId = "";
  networkInMetrics = [];
  networkOutMetrics = [];
  cpuMetrics = [];
  diskMetrics = [];
  loadMetrics = [];
  fileMetrics = [];
  memoryMetrics = [];

  constructor() {
    this.reset();
    makeObservable(this, {
      setLoading: action,
      isLoading: observable,
      setMetrics: action,
      metrics: observable,
      setMetricsAggregrateFunction: action,
      setMetricsObjectDuration: action,
      setCPUMetrics: action,
      setNetworkMetrics: action,
      setDiskMetrics: action,
      setDeviceId: action,
      deviceId: observable,
      setNetworkMetricsValue: action,
      setNetworkOutMetricsValue: action,
      networkInMetrics: observable,
      networkOutMetrics: observable,
      setCpuMetricsValue: action,
      cpuMetrics: observable,
      setDiskMetricsValue: action,
      diskMetrics: observable,
      memoryMetrics: observable,
      getMemoryMetricsFromServer: action,
      getNetworkMetricsFromServer: action,
      getCpuMetricsFromServer: action,
      getDiskMetricsFromServer: action,
      fetchAllMetrics: action,
    });
  }

  reset = () => {
    Object.keys(initialValues).forEach(key => {
      this[key] = initialValues[key];
    });
  };

  setLoading(value: boolean) {
    this.isLoading = value;
  }

  setMetricsAggregrateFunction = (value: string) => {
    this.metrics.aggregateFunc = value;
  };

  setMetricsObjectDuration = (fromDate: string, toDate: string) => {
    this.metrics.fromDate = fromDate;
    this.metrics.toDate = toDate;
    const dateFrom = dayjs(fromDate);
    const dateTo = dayjs(toDate);
    const diffInDays = dateTo.diff(dateFrom, "day");
    const diffInhours = dateTo.diff(dateFrom, "hour");
    if (diffInDays > 3) {
      this.interval = "1d";
    } else if (diffInDays > 1) {
      this.interval = "1h";
    } else if (diffInhours > 3) {
      this.interval = "5m";
    } else {
      this.interval = "60s";
    }
  };

  setCPUMetrics = (value: IVisualizationFieldsObject) => {
    this.metrics.visualization.cpu = value;
  };

  setNetworkMetrics = (value: IVisualizationFieldsObject) => {
    this.metrics.visualization.network = value;
  };

  setDiskMetrics = (value: IVisualizationFieldsObject) => {
    this.metrics.visualization.disk = value;
  };

  setFileMetrics = (value: IVisualizationFieldsObject) => {
    this.metrics.visualization.file = value;
  };

  setMemoryMetrics = (value: IVisualizationFieldsObject) => {
    this.metrics.visualization.memory = value;
  };

  setLoadMetrics = (value: IVisualizationFieldsObject) => {
    this.metrics.visualization.load = value;
  };

  setMetrics = values => {
    this.metrics = values;
  };

  setDeviceId = (id: string) => {
    this.deviceId = id;
  };

  setNetworkMetricsValue = response => {
    const recievedData = response.find(
      res => res?.metric?.direction === "receive",
    );
    const transmittedData = response.find(
      res => res?.metric?.direction === "transmit",
    );
    this.networkInMetrics = recievedData?.values || [];
    this.networkOutMetrics = transmittedData?.values || [];
  };

  setCpuMetricsValue = response => {
    this.cpuMetrics = response?.[0]?.values || [];
  };

  setDiskMetricsValue = response => {
    this.diskMetrics = response?.[0]?.values || [];
  };

  setLoadMetricsValue = response => {
    this.loadMetrics = response?.[0]?.values || [];
  };

  setFileMetricsValue = response => {
    this.fileMetrics = response?.[0]?.values || [];
  };

  setMemoryMetricsValue = response => {
    this.memoryMetrics = response?.[0]?.values || [];
  };

  setNetworkOutMetricsValue = response => {
    this.networkInMetrics = response?.[0]?.values || [];
    this.networkOutMetrics = response?.[0]?.values || [];
  };

  getCpuMetricsFromServer = async (deviceId: string) => {
    const requestBody = {
      metrics: [MetricsTypes.CPU_UTILIZATION],
      deviceIds: [`${deviceId}`],
      timeInterval: `${this.interval}`,
      fromDate: this.metrics.fromDate,
      toDate: this.metrics.toDate,
    };
    const response = await getDeviceMetrics({
      ...requestBody,
      deviceIds: ["shoaib-compute"],
    }).catch(error => {
      ErrorReporter.collect(error, "Error while fetching CPU metrics");
      this.setLoading(false);
      throw error;
    });
    if (!response) throw new Error("Data Not found");

    this.setCPUMetrics({
      type: "cpu",
      timeInterval: requestBody.timeInterval,
      aggregateFunction: this.metrics.aggregateFunc,
      allowedIntervals: [],
    });

    this.setCpuMetricsValue(response?.payload?.data?.result || []);
    this.setLoading(false);
    return response;
  };

  getNetworkMetricsFromServer = async (deviceId: string) => {
    const requestBody = {
      metrics: [MetricsTypes.NETWORK_UTILIZATION],
      deviceIds: [`${deviceId}`],
      timeInterval: `${this.interval}`,
      fromDate: this.metrics.fromDate,
      toDate: this.metrics.toDate,
    };
    const response = await getDeviceMetrics({
      ...requestBody,
      deviceIds: ["shoaib-compute"],
    }).catch(error => {
      ErrorReporter.collect(error, "Error while fetching Network metrics");
      this.setLoading(false);
      throw error;
    });
    if (!response) throw new Error("Data Not found");

    this.setNetworkMetrics({
      type: "network",
      timeInterval: requestBody.timeInterval,
      aggregateFunction: this.metrics.aggregateFunc,
      allowedIntervals: [],
    });

    this.setNetworkMetricsValue(response?.payload?.data?.result || []);
    this.setLoading(false);
    return response;
  };

  getDiskMetricsFromServer = async (deviceId: string) => {
    const requestBody = {
      metrics: [MetricsTypes.DISK_UTILIZATION],
      deviceIds: [`${deviceId}`],
      timeInterval: `${this.interval}`,
      fromDate: this.metrics.fromDate,
      toDate: this.metrics.toDate,
    };
    const response = await getDeviceMetrics({
      ...requestBody,
      deviceIds: ["shoaib-compute"],
    }).catch(error => {
      ErrorReporter.collect(error, "Error while fetching Disk metrics");
      this.setLoading(false);
      throw error;
    });
    if (!response) throw new Error("Data Not found");

    this.setDiskMetrics({
      type: "disk",
      timeInterval: requestBody.timeInterval,
      aggregateFunction: this.metrics.aggregateFunc,
      allowedIntervals: [],
    });

    this.setDiskMetricsValue(response?.payload?.data?.result || []);
    this.setLoading(false);
    return response;
  };

  getFileMetricsFromServer = async (deviceId: string) => {
    const requestBody = {
      metrics: [MetricsTypes.FILE_SYSTEM_UTILIZATION],
      deviceIds: [`${deviceId}`],
      timeInterval: `${this.interval}`,
      fromDate: this.metrics.fromDate,
      toDate: this.metrics.toDate,
    };
    const response = await getDeviceMetrics({
      ...requestBody,
      deviceIds: ["shoaib-compute"],
    }).catch(error => {
      ErrorReporter.collect(error, "Error while fetching File metrics");
      this.setLoading(false);
      throw error;
    });
    if (!response) throw new Error("Data Not found");

    this.setFileMetrics({
      type: "file",
      timeInterval: requestBody.timeInterval,
      aggregateFunction: this.metrics.aggregateFunc,
      allowedIntervals: [],
    });

    this.setFileMetricsValue(response?.payload?.data?.result || []);
    this.setLoading(false);
    return response;
  };

  getMemoryMetricsFromServer = async (deviceId: string) => {
    const requestBody = {
      metrics: [MetricsTypes.MEMORY_UTILIZATION],
      deviceIds: [`${deviceId}`],
      timeInterval: `${this.interval}`,
      fromDate: this.metrics.fromDate,
      toDate: this.metrics.toDate,
    };
    const response = await getDeviceMetrics({
      ...requestBody,
      deviceIds: ["shoaib-compute"],
    }).catch(error => {
      ErrorReporter.collect(error, "Error while fetching Memory metrics");
      this.setLoading(false);
      throw error;
    });
    if (!response) throw new Error("Data Not found");

    this.setFileMetrics({
      type: "memory",
      timeInterval: requestBody.timeInterval,
      aggregateFunction: this.metrics.aggregateFunc,
      allowedIntervals: [],
    });

    this.setMemoryMetricsValue(response?.payload?.data?.result || []);
    this.setLoading(false);
    return response;
  };

  getLoadMetricsFromServer = async (deviceId: string) => {
    const requestBody = {
      metrics: [MetricsTypes.SYSTEM_LOAD_UTILIZATION],
      deviceIds: [`${deviceId}`],
      timeInterval: `${this.interval}`,
      fromDate: this.metrics.fromDate,
      toDate: this.metrics.toDate,
    };
    const response = await getDeviceMetrics({
      ...requestBody,
      deviceIds: ["shoaib-compute"],
    }).catch(error => {
      ErrorReporter.collect(error, "Error while fetching Load metrics");
      this.setLoading(false);
      throw error;
    });
    if (!response) throw new Error("Data Not found");

    this.setLoadMetrics({
      type: "load",
      timeInterval: requestBody.timeInterval,
      aggregateFunction: this.metrics.aggregateFunc,
      allowedIntervals: [],
    });

    this.setLoadMetricsValue(response?.payload?.data?.result || []);
    this.setLoading(false);
    return response;
  };

  fetchAllMetrics = async (deviceId: string) => {
    await this.getCpuMetricsFromServer(deviceId);
    await this.getDiskMetricsFromServer(deviceId);
    await this.getFileMetricsFromServer(deviceId);
    await this.getLoadMetricsFromServer(deviceId);
    await this.getNetworkMetricsFromServer(deviceId);
  };
}

export default new MetricesStore();
