import { Roarr, ROARR } from "roarr";
import { IlogContextTypes } from "../interfaces/logInterfaces";

class Logger {
  private logger;
  constructor(context: IlogContextTypes) {
    this.logger = Roarr.child({
      ...context,
    });
  }

  debug(data, message) {
    Roarr.debug(data, message);
  }

  info(data, message) {
    this.logger.info(data, message);
  }

  warn(data, message) {
    this.logger.warn(data, message);
  }

  error(data, message) {
    this.logger.error(data, message);
  }

  trace(data, message) {
    this.logger.trace(data, message);
  }

  public static createLogs = () => {
    ROARR.write = message => {
      console.log(JSON.parse(message));
    };
  };
}

export default Logger;
