export const API_BASE_URL =
  process.env.NEXT_PUBLIC_API_BASE_URL || "https://cloud-api.sandbox.mecha.build";
export const KRATOS_API_URL =
  process.env.NEXT_PUBLIC_KRATOS_API_URL || "https://auth.dev.mecha.build";
export const LC_BASE_URL =
  process.env.NEXT_PUBLIC_LC_BASE_URL || "https://app-lc.v1.dev.mecha.build";
export const STORE_BASE_URL =
  process.env.NEXT_PUBLIC_STORE_BASE_URL ||
  "https://app-store.v1.dev.mecha.build";

export const isDev = process.env.NEXT_PUBLIC_NODE_ENV === "dev";
export const COOKIE_DOMAIN =
  process.env.NEXT_PUBLIC_DOMAIN_URL || ".dev.mecha.build";

export const GITHUB_CLIENT_ID = process.env.NEXT_PUBLIC_GITHUB_CLIENT_ID || "";

export const WEBSITE_URL =
  process.env.NEXT_PUBLIC_WEBSITE_URL || "https://app.v1.dev.mecha.build";

export const WEBSITE_DOCS_URL =
  process.env.NEXT_PUBLIC_DOCS_URL || "https://app.v1.dev.mecha.build/docs";

export const GITHUB_APPLICATION_ID =
  process.env.NEXT_PUBLIC_GITHUB_APPLICATION_ID || "";

export const CLOUD_API_AUTH_TOKEN =
  process.env.CLOUD_API_AUTH_TOKEN || "MECHA_AUTH_TOKEN";

export const BLOG_HOST_URL = process.env.NEXT_PUBLIC_BLOG_HOST_URL || "";
export const FORUMS_HOST_URL = process.env.NEXT_PUBLIC_FORUMS_HOST_URL || "";
export const IS_EDGE_RUNTIME_ENABLED =
  process.env.NEXT_PUBLIC_IS_EDGE_RUNTIME_ENABLED == "TRUE";

export const PUSHER_KEY = process.env.NEXT_PUBLIC_PUSHER_APP_KEY || "";

export const SOKETI_HOST = process.env.NEXT_PUBLIC_WEB_SOCKET_HOST || "";

export const PUSHER_CLUSTER = process.env.NEXT_PUBLIC_WEB_SOCKET_CLUSTER || "";

export const MECHA_WEBSITE_URL =
  process.env.NEXT_PUBLIC_MECHA_WEBSITE_URL ||
  "https://website.v1.dev.mecha.build/";

export const TURNSTILE_SITE_KEY =
  process.env.NEXT_PUBLIC_TURNSTILE_SITE_KEY || "";

export const TURNSTILE_TESTING_SITE_KEY =
  process.env.NEXT_PUBLIC_TURNSTILE_TESTING_SITE_KEY || "";

export const TURNSTILE_DUMMY_TOKEN =
  process.env.NEXT_PUBLIC_TURNSTILE_DUMMY_TOKEN || "";

export const DISCOURSE_URL = process.env.NEXT_SERVER_DISCOURSE_URL || "";
