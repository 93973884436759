import { action, makeObservable, observable } from "mobx";
import {
  addDeviceProvisionApi,
  getDevicesApi,
  getDeviceTemplates,
  getFirewallRules,
  getPinnedDevicesApi,
  getSingleDevice,
  UpdateDeviceInfoApi,
} from "../../api/devicesApis";
import { FirewallDirection, MAX_PINNED_DEVICE } from "../../config/constants";
import {
  DeviceModalSteps,
  IDeviceContent,
  IDeviceProvisionReq,
  IDeviceSearch,
  IDeviceTemplates,
  IEventsData,
  IFirewallRule,
  ILogsData,
  IUpdateDeviceReq,
} from "../../interfaces/devicesInterfaces";
import AuthStore from "./authStore";

import { ErrorReporter } from "../../libs/ErrorReporter";

type IDeviceStoreFields = {
  isEventsPayloadModalOpen: boolean;
  eventsPayloadData: Partial<IEventsData>;
  isLogsPayloadModalOpen: boolean;
  logsPayloadData: Partial<ILogsData>;
  addDeviceModal: boolean;
  addDeviceModalLoading: boolean;
  modalCurrentStep: number;
  provisionDeviceId: string;
  provisionRequestId: number;
  devicesList: IDeviceContent[];
  pinnedDevicesList: IDeviceContent[];
  loading: boolean;
  totalRecords: number;
  offset: number;
  limit: number;
  deviceSearch: IDeviceSearch;
  deviceTemplates: IDeviceTemplates[];
  deviceDetails: Partial<IDeviceContent>;
  currentDeviceId: string;
  isDeviceOnline: boolean;
  deviceInfo: Partial<IDeviceContent>;
  inboundRules: Partial<IFirewallRule[]>;
  outboundRules: Partial<IFirewallRule[]>;
};
const initialValues: IDeviceStoreFields = {
  isEventsPayloadModalOpen: false,
  eventsPayloadData: {},
  isLogsPayloadModalOpen: false,
  logsPayloadData: {},
  addDeviceModal: false,
  addDeviceModalLoading: true,
  modalCurrentStep: DeviceModalSteps.STEP1,
  devicesList: [],
  deviceTemplates: [],
  pinnedDevicesList: [],
  loading: false,
  totalRecords: 0,
  offset: 1,
  limit: 10,
  deviceSearch: { tags: [], searchText: "" },
  deviceDetails: {},
  currentDeviceId: "",
  provisionDeviceId: "",
  provisionRequestId: -1,
  isDeviceOnline: false,
  deviceInfo: {},
  inboundRules: [],
  outboundRules: [],
};
class DeviceStore {
  isEventsPayloadModalOpen: boolean;
  eventsPayloadData: Partial<IEventsData>;
  isLogsPayloadModalOpen: boolean;
  logsPayloadData: Partial<ILogsData>;
  addDeviceModal: boolean;
  addDeviceModalLoading: boolean;
  modalCurrentStep: number;
  devicesList: IDeviceContent[];
  pinnedDevicesList: IDeviceContent[];
  loading: boolean;
  totalRecords: number;
  offset: number;
  limit: number;
  deviceSearch: IDeviceSearch;
  deviceTemplates: IDeviceTemplates[];
  deviceDetails: Partial<IDeviceContent>;
  currentDeviceId: string;
  provisionDeviceId: string;
  provisionRequestId: number;
  isDeviceOnline: boolean;
  deviceInfo: Partial<IDeviceContent>;
  inboundRules: Partial<IFirewallRule[]>;
  outboundRules: Partial<IFirewallRule[]>;

  constructor() {
    this.reset();
    makeObservable(this, {
      updateDeviceById: action,
      getDeviceById: action,
      fetchInboundRules: action,
      fetchOutboundRules: action,
      fetchFirewallRules: action,
      getAllDevices: action,
      getAllPinnedDevices: action,
      getBothDevices: action,
      getDeviceTemplates: action,
      createDeviceProvisioningRequest: action,
      handleAddModalClose: action,
      updateDevice: action,
      updateDeviceInfo: action,
      setDeviceSearch: action,
      setCurrentDeviceId: action,
      setOffset: action,
      setLimit: action,
      setAddDeviceModal: action,
      setAddDeviceModalLoading: action,
      setModalCurrentStep: action,
      setEventsPayloadModalOpen: action,
      setEventsPayloadData: action,
      setLogsPayloadModalOpen: action,
      setLogsPayloadData: action,
      setLoading: action,
      setProvisionDeviceId: action,
      setProvisionRequestId: action,
      setDeviceDetails: action,
      setIsDeviceOnline: action,
      setDeviceInfo: action,
      setInboundRules: action,
      setOutboundRules: action,
      isEventsPayloadModalOpen: observable,
      eventsPayloadData: observable,
      isLogsPayloadModalOpen: observable,
      logsPayloadData: observable,
      addDeviceModal: observable,
      addDeviceModalLoading: observable,
      modalCurrentStep: observable,
      devicesList: observable,
      pinnedDevicesList: observable,
      loading: observable,
      totalRecords: observable,
      offset: observable,
      limit: observable,
      deviceSearch: observable,
      deviceTemplates: observable,
      deviceDetails: observable,
      currentDeviceId: observable,
      provisionDeviceId: observable,
      provisionRequestId: observable,
      isDeviceOnline: observable,
      deviceInfo: observable,
      inboundRules: observable,
      outboundRules: observable,
    });
  }

  reset = () => {
    Object.keys(initialValues).forEach(key => {
      this[key] = initialValues[key];
    });
  };

  updateDeviceById = async (body: IUpdateDeviceReq) => {
    try {
      const res = await UpdateDeviceInfoApi(this.currentDeviceId, body);
      this.getAllDevices();
      return res;
    } catch (error) {
      ErrorReporter.collect(error, "Error while updating device by Id", {
        deviceId: this.currentDeviceId,
      });
    }
  };

  getDeviceById = async (deviceId: string) => {
    try {
      const res = await getSingleDevice(deviceId);
      this.setDeviceInfo(res?.payload);
      return res;
    } catch (error) {
      ErrorReporter.collect(error, "Error while fetching device by Id", {
        deviceId: this.currentDeviceId,
      });
    }
  };

  fetchInboundRules = async (device_id_alias: string) => {
    try {
      const res = await getFirewallRules(
        device_id_alias,
        FirewallDirection.INBOUND,
      );

      if (res?.success) {
        this.setInboundRules(res?.payload?.content);
        return res?.payload?.content;
      }
    } catch (error) {
      ErrorReporter.collect(
        error,
        "Error while fetching firewall inbound rules",
        {
          deviceId: this.currentDeviceId,
        },
      );
    }
  };

  fetchOutboundRules = async (device_id_alias: string) => {
    try {
      const res = await getFirewallRules(
        device_id_alias,
        FirewallDirection.OUTBOUND,
      );
      if (res?.success) {
        this.setOutboundRules(res?.payload?.content);
        return res?.payload?.content;
      }
    } catch (error) {
      ErrorReporter.collect(
        error,
        "Error while fetching firewall outbound rules",
        {
          deviceId: this.currentDeviceId,
        },
      );
    }
  };

  fetchFirewallRules = async (device_id_alias: string) => {
    try {
      await this.fetchInboundRules(device_id_alias);
      await this.fetchOutboundRules(device_id_alias);
    } catch (error) {
      ErrorReporter.collect(error, "Error while fetching firewall rules", {
        deviceId: this.currentDeviceId,
      });
    }
  };

  getAllDevices = async () => {
    try {
      this.loading = true;
      const res = await getDevicesApi(
        this.offset,
        this.limit,
        AuthStore.loggedInUser.id,
        this.deviceSearch?.searchText,
        this.deviceSearch?.tags,
      );
      if (res) {
        this.devicesList = res?.payload?.content || [];
        this.totalRecords = res?.payload?.totalRecordCount || 0;
      }
    } catch (error) {
      ErrorReporter.collect(error, "Error while fetching all devices", {
        userId: AuthStore.loggedInUser.userAccountId,
      });
    } finally {
      this.loading = false;
    }
  };

  getAllPinnedDevices = async () => {
    try {
      const res = await getPinnedDevicesApi(
        MAX_PINNED_DEVICE,
        AuthStore.loggedInUser.id,
      );
      this.pinnedDevicesList = res?.payload?.content;
    } catch (error) {
      ErrorReporter.collect(error, "Error while fetching all pinned devices", {
        userId: AuthStore.loggedInUser.id,
      });
    }
  };

  getBothDevices = async () => {
    this.getAllDevices();
  };

  getDeviceTemplates = async () => {
    try {
      const res = await getDeviceTemplates();
      const parsedData = res?.payload?.map(item => ({
        ...item,
        setupInstructions: JSON.parse(
          JSON.parse(item.setupInstructions as string),
        ),
      }));
      this.deviceTemplates = [...parsedData];
    } catch (error) {
      ErrorReporter.collect(error, "Error while fetching device templates");
    }
  };

  createDeviceProvisioningRequest = async (
    request: IDeviceProvisionReq,
  ): Promise<boolean> => {
    try {
      const response = await addDeviceProvisionApi(request);
      this.setProvisionDeviceId(response.payload?.deviceId);
      this.setProvisionRequestId(response.payload?.requestId);
      return true;
    } catch (error) {
      ErrorReporter.collect(error, "Error while addDeviceProvisionApi ");
      return false;
    }
  };

  handleAddModalClose = () => {
    this.setAddDeviceModal(false);
    this.setModalCurrentStep(DeviceModalSteps.STEP1);
    this.setCurrentDeviceId("");
    this.setDeviceDetails({});
    this.setProvisionDeviceId("");
    this.setProvisionRequestId(-1);
    this.getAllDevices();
  };

  updateDevice = (deviceId: string, toUpdate: Partial<IDeviceContent>) => {
    const deviceIndex: number = this.devicesList.findIndex(
      d => d.deviceId === deviceId,
    );
    if (deviceIndex === -1) {
      return;
    }
    const devices = this.devicesList.slice();
    devices[deviceIndex] = { ...devices[deviceIndex], ...toUpdate };
    this.devicesList = devices;
  };

  updateDeviceInfo = toUpdate => {
    const updatedData = { ...this.deviceInfo, ...toUpdate };
    this.deviceInfo = updatedData;
  };

  setDeviceSearch = (data: Partial<IDeviceSearch>) => {
    this.deviceSearch = Object.assign({}, this.deviceSearch, data);
    this.getAllDevices();
  };

  setCurrentDeviceId = (id: string) => {
    this.currentDeviceId = id;
  };

  setOffset = (offset: number) => {
    this.offset = offset;
  };

  setLimit = (limit: number) => {
    this.limit = limit;
  };

  setAddDeviceModal = (value: boolean) => {
    this.addDeviceModal = value;
  };

  setAddDeviceModalLoading = (value: boolean) => {
    this.addDeviceModalLoading = value;
  };

  setModalCurrentStep = (step: number) => {
    this.modalCurrentStep = step;
  };

  setEventsPayloadModalOpen = (value: boolean) => {
    this.isEventsPayloadModalOpen = value;
  };

  setEventsPayloadData = (values: IEventsData) => {
    this.eventsPayloadData = values;
  };

  setLogsPayloadModalOpen = (value: boolean) => {
    this.isLogsPayloadModalOpen = value;
  };

  setLogsPayloadData = (values: IEventsData) => {
    this.logsPayloadData = values;
  };

  setLoading = (value: boolean) => {
    this.loading = value;
  };

  setProvisionDeviceId = (value: string) => {
    this.provisionDeviceId = value;
  };

  setProvisionRequestId = (value: number) => {
    this.provisionRequestId = value;
  };

  setDeviceDetails = (value: Partial<IDeviceContent>) => {
    this.deviceDetails = value;
  };

  setIsDeviceOnline = (value: boolean) => {
    this.isDeviceOnline = value;
  };

  setDeviceInfo = (value: Partial<IDeviceContent>) => {
    this.deviceInfo = value;
  };

  setInboundRules = (value: Partial<IFirewallRule[]>) => {
    this.inboundRules = value;
  };

  setOutboundRules = (value: Partial<IFirewallRule[]>) => {
    this.outboundRules = value;
  };
}

export default new DeviceStore();
