import createStore from "../createStore";
import authStore from "./authStore";
import deviceStore from "./deviceStore";
import metricesStore from "./deviceMetricsStore";
import automationStore from "./automationStore";

export const store = {
  authStore,
  deviceStore,
  metricesStore,
  automationStore,
};
export const {
  Consumer: StoreConsumer,
  Provider: StoreProvider,
  useStore,
} = createStore(store);
