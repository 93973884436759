import { API_BASE_URL, IS_EDGE_RUNTIME_ENABLED } from "../../config/envConstants";
import { IResponse } from "../../interfaces/api";
import { IAutomaionsPayload, IAutomationInfo, IAutomationVersions} from "../../interfaces/automationInterfaces";
import { CloudApiClient } from "../cloudApiClient";

const API_VERSION = "v1";
const API_URL_SUFFIX = "/api/";
const API_URL = API_BASE_URL + API_URL_SUFFIX + API_VERSION;

export const getAutomations = (
  userId: string,
): Promise<IResponse<IAutomaionsPayload>> => {
  return CloudApiClient.httpGet(`${API_URL}/automations/user`);
};

export const getSystemAutomations = () => {
  return CloudApiClient.httpGet(`${API_URL}/automations/system`);
};

export const getAutomationDetails = (
  automationId: string,
): Promise<IResponse<IAutomationInfo>> => {
  return CloudApiClient.httpGet(
    `${API_URL}/automations/${automationId}/details`,
  );
};

export const getAutomationVersions = (
  automationId: string,
): Promise<IResponse<IAutomationVersions[]>> => {
  return CloudApiClient.httpGet(
    `${API_URL}/automations/${automationId}/versions`,
  );
};
