import { API_BASE_URL, KRATOS_API_URL } from "../../config/envConstants";
import {
  IResetPasswordTokenReq,
  IEmailTokenReq,
  IGetCurrentUserRes,
  ILoginReq,
  ILoginRes,
} from "../../interfaces/authInterfaces";
import { CloudApiClient } from "../cloudApiClient";

const API_VERSION = "v1";
const AUTH_API_PREFIX = `${API_VERSION}/auth`;
const API_URL_SUFFIX = "/api/";

const API_URL = API_BASE_URL + API_URL_SUFFIX + AUTH_API_PREFIX;


export const loginApi = (body: ILoginReq): Promise<ILoginRes> => {
  return CloudApiClient.httpPost(`${API_URL}/signin`, body);
};

export const getLoggedInUserApi = (): any => {
  return CloudApiClient.httpGet(`${API_URL}/whoami`);
};

export const getForgotPasswordLinkApi = (
  body: IResetPasswordTokenReq,
): Promise<IGetCurrentUserRes> => {
  return CloudApiClient.httpPost(`${API_URL}/request-reset-password`, body);
};

export const CheckUsernameApi = (username: string): Promise<any> => {
  return CloudApiClient.httpGet(
    `${API_URL}/check-username-inuse?username=${username}`,
  );
};

export const CheckEmailApi = (email: string): Promise<any> => {
  return CloudApiClient.httpGet(`${API_URL}/check-email-inuse?email=${email}`);
};

export const ChangePasswordApi = (flowId: string, data: any): Promise<any> => {
  return CloudApiClient.httpPost(
    `${KRATOS_API_URL}/self-service/settings?flow=${flowId}`,
    data,
  );
};

export const GetFlowIdForGithubApi = (): Promise<any> => {
  return CloudApiClient.httpGet(`${KRATOS_API_URL}/self-service/login/browser`);
};

export const verifyEmail = (body: IEmailTokenReq): Promise<any> => {
  return CloudApiClient.httpPost(`${API_URL}/request-email-verification`, body);
};
